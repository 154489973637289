body {
  margin: 0;
  font-family: "Rubik", 'Verdana', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 300;
}

input, textarea, button {font-family: inherit}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"),
   url("./assets/fonts/Rubik-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Rubik";
  src: local("Rubik"),
    url("./assets/fonts/Rubik-Italic-VariableFont_wght.ttf") format("truetype");
  font-style: italic;
}

b, h1 {
  font-weight: 400;
}

button[type="submit"] {
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
}

body, html, #root, #blank {
  background-color: #f6f8fc;
}

::-webkit-search-cancel-button{ 
  background-image: url("./assets/icons8/icons8-cancel-64.png");
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 10px;
  background-size: 16px;
  background-repeat: no-repeat;
  cursor: pointer;
}

input[type="search"] {
  -webkit-appearance: none;
}

::-webkit-scrollbar {
  height:16px;
  overflow:visible;
  width:10px
}

::-webkit-scrollbar-button {
  height: 0;
  width: 0;
}

::-webkit-scrollbar-track {
  background-clip: padding-box;
  background-color: #f6f8fc;
}

::-webkit-scrollbar-track:hover { 
  background-color:#f3f3f3;
}

::-webkit-scrollbar-track:active { 
  background-color:#f3f3f3;
}

::-webkit-scrollbar-thumb {
  background-color:#efefef;
  border-radius: 10px;
  background-clip:padding-box;
  min-height: 28px;
  padding: 100px 0 0;
  margin: 5px;
}

::-webkit-scrollbar-thumb:hover { 
  background-color:#cccccc;
}

::-webkit-scrollbar-thumb:active {
  background-color:#999999;
}